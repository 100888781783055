import {
  Autocomplete,
  Box, IconButton, TextField, Typography,
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Form, Formik } from 'formik';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';

import * as yup from 'yup';
import dayjs from 'dayjs';
import { Timestamp } from 'firebase/firestore';
import { useState } from 'react';
import { Close, Delete, Edit } from '@mui/icons-material';
import Suivi from '../../../models/Suivi';
import Utils from '../../../utils/Utils';
import translator from '../../../theme/translator.json';
import { useAppDispatch, useAppSelector } from '../../../store/Hooks';
import SupportedLanguages from '../../../models/enums/SupportedLanguages';
import SuiviService from '../../../services/SuiviService';
import AlertUtils from '../../../utils/AlertUtil';
import PricingPlan from '../../../models/enums/PricingPlans';

  type Props = {
      clientId: string,
      suivi?: Suivi,
      setLoading: any,
      handleOpenDeleteSuiviDialog: any,
  };

const SuiviCard: React.FC<Props> = ({
  clientId, suivi, setLoading, handleOpenDeleteSuiviDialog,
}) => {
  const user = useAppSelector((state) => state.user.user);
  const language = useAppSelector((state) => state.user.language) as SupportedLanguages;
  const [isEditingDate, setIsEditingDate] = useState(false);
  const dispatch = useAppDispatch();

  const suiviInitialValues = () : {date:number, notes:string, vendeur:string} => {
    let date = dayjs().hour(23).unix() * 1000;

    let notes = '';
    let vendeur = '';

    if (suivi) {
      date = suivi.date.seconds * 1000;
      notes = suivi.notes || '';
      vendeur = suivi.vendeur || '';
    }
    return {
      date,
      notes,
      vendeur,
    };
  };

  return (

    <Box sx={{
      width: '100%',
      pb: 3,
      borderBottom: '1px solid #efefef',
    }}>
      <Formik
        initialValues={suiviInitialValues()}
        enableReinitialize
        validationSchema={yup.object({
          date: yup.string().required(Utils.getTranslation(language, translator.formMessages.requiredField)),
          notes: yup.string(),
          vendeur: yup.string(),
        })}
        onSubmit={async (values, { setSubmitting }) => {
          try {
            setLoading(true);
            if (suivi && suivi.id) {
              await SuiviService.update(user.id, suivi.id, {
                date: Timestamp.fromDate(new Date(values.date)),
                notes: values.notes,
                vendeur: values.vendeur,
              });
            } else {
              await SuiviService.create(user.id, clientId, values.notes, Timestamp.fromDate(new Date(values.date)), values.vendeur);
            }
            setSubmitting(false);
            setLoading(false);
            AlertUtils.createSuccessAlert(Utils.getTranslation(language, translator.successMessages.noteSaved), dispatch);
          } catch (e) {
            setLoading(false);
            AlertUtils.createErrorAlert(Utils.getTranslation(language, translator.errorMessages.general.unknown), dispatch);
          }
        }}
      >
        {(formikProps) => (
          <Form>
            <Box sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'top',
              gap: '10px',
              '@media (max-width:600px)': {
                flexWrap: 'wrap',
              },
            }}>
              <Box sx={{ width: '200px' }}>
                {isEditingDate
                  ? <Box sx={{
                    '@media (max-width:600px)': {
                      display: 'flex',
                      alignItems: 'center',
                      gap: 2,
                      mb: 1,
                    },
                  }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <MobileDatePicker
                        label={Utils.getTranslation(language, translator.formLabels.suiviDate)}
                        value={dayjs.unix(formikProps.values.date / 1000)}
                        onChange={(value) => {
                          formikProps.setFieldValue('date', (value) ? value.hour(23).unix() * 1000 : dayjs().hour(23).unix() * 1000, false);
                          if ((suivi)
                    || (!suivi && formikProps.values.notes)
                          ) {
                            formikProps.handleSubmit();
                          }
                          setIsEditingDate(false);
                        }}
                        inputFormat="DD/MM/YYYY"
                        renderInput={(params: any) => <TextField
                          error={formikProps.touched.notes && Boolean(formikProps.errors.notes)}
                          {...params}
                        />}
                      />
                    </LocalizationProvider>
                    <IconButton onClick={() => setIsEditingDate(false)} size="small"><Close fontSize="small" /></IconButton>
                  </Box>
                  : <Box sx={{
                    '@media (max-width:600px)': {
                      display: 'flex',
                      alignItems: 'center',
                      gap: 2,
                      mb: 1,
                    },
                  }}>
                    {formikProps.values.date && <Typography>{Utils.renderSuiviDate(dayjs.unix(formikProps.values.date / 1000))}</Typography>}
                    <Box>
                      <IconButton onClick={() => setIsEditingDate(true)} size="small"><Edit fontSize="small" /></IconButton>
                      {(suivi && suivi.id) && <IconButton onClick={() => handleOpenDeleteSuiviDialog(suivi)} size="small"><Delete fontSize="small" /></IconButton>}
                    </Box>
                  </Box>
                }
              </Box>
              <Box sx={{
                width: '100%', display: 'flex', gap: '10px', flexWrap: 'wrap',
              }}>
                {user?.plan === PricingPlan.PREMIUM && user.showVisites
                && <Autocomplete
                  sx={{ minWidth: '150px' }}
                  id="vendeur"
                  options={(user.vendeurs) ? user.vendeurs : []}
                  value={formikProps.values.vendeur}
                  size='small'
                  onChange={(e, value) => {
                    console.log(value);
                    formikProps.setFieldValue('vendeur', (value) || '');
                    formikProps.handleSubmit();
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Vendeur"
                    />
                  )}
                />}
                <TextField
                  label={Utils.getTranslation(language, translator.formLabels.besoinsEtNotes)}
                  variant="outlined"
                  fullWidth
                  multiline
                  name="notes"
                  value={formikProps.values.notes}
                  onChange={formikProps.handleChange}
                  onBlur={() => {
                    if ((suivi && formikProps.values.notes !== suivi.notes)
                  || (!suivi && formikProps.values.notes)
                    ) {
                      formikProps.handleSubmit();
                    }
                  }}
                  error={formikProps.touched.notes && Boolean(formikProps.errors.notes)}
                  helperText={formikProps.touched.notes && formikProps.errors.notes}
                />
              </Box>
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default SuiviCard;
