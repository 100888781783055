import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box, Container, Tab, Tabs,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { ArrowDownward, ArrowDropDown } from '@mui/icons-material';
import { useAppSelector } from '../store/Hooks';
import SupportedLanguages from '../models/enums/SupportedLanguages';
import Layout from '../components/layout/Layout';
import translator from '../theme/translator.json';
import Utils from '../utils/Utils';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const {
    children, value, index, ...other
  } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

const ReportsPage: React.FC = () => {
  const { visites } = useAppSelector((state) => state.user);
  const language = useAppSelector((state) => state.user.language) as SupportedLanguages;
  const [tabValue, setTabValue] = useState(0);
  const [currentVisites, setCurrentVisites] = useState([]) as any;
  const [weeklySortedVisites, setWeeklySortedVisites] = useState([]) as any;

  useEffect(() => {
    if (visites && visites.length) {
      const tempVisites = [...visites];
      setCurrentVisites(tempVisites.reverse());
    }
  }, [visites]);

  useEffect(() => {
    if (currentVisites && currentVisites.length) {
      let startOfWeek = dayjs().startOf('week').unix();
      let currentWeekVisites = [] as any;
      const weeklySortedVisitesTemp = [] as any;
      currentVisites.forEach((visite:any) => {
        if (visite.date.seconds < startOfWeek) {
          weeklySortedVisitesTemp.push(currentWeekVisites);
          currentWeekVisites = [];
          startOfWeek = dayjs.unix(visite.date.seconds).startOf('week').unix();
        }

        currentWeekVisites.push(visite);
      });
      weeklySortedVisitesTemp.push(currentWeekVisites);
      currentWeekVisites = [];
      setWeeklySortedVisites(weeklySortedVisitesTemp);
    }
  }, [currentVisites]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const displayWeek = (week:any, index:any) => {
    if (!week.length) return (<></>);
    const weekDate = dayjs.unix(week[0].date.seconds).startOf('week').format('DD/MM/YYYY');

    const weekReduced = week.reduce((memo:any, x:any) => {
      if (!memo[x.vendeur]) { memo[x.vendeur] = []; }
      memo[x.vendeur].push(x);
      return memo;
    }, {});

    console.log(weekReduced);

    return (
      <Accordion key={index}>
        <AccordionSummary
          expandIcon={<ArrowDownward />}
        >
          <Typography>{weekDate}</Typography>
        </AccordionSummary>
        <AccordionDetails>

          {Object.keys(weekReduced).map((key:any, i:any) => (
            <Accordion key={i}>
              <AccordionSummary
                expandIcon={<ArrowDropDown />}
              >
                <Typography><strong>{key}</strong> - {weekReduced[key].length} visites</Typography>
              </AccordionSummary>
              <AccordionDetails>
                {weekReduced[key].map((v:any, j:any) => (
                  <Box key={j} sx={{ marginBottom: 2 }}>
                    <Typography>{dayjs.unix(v.date.seconds).format('DD/MM/YYYY')} - {v.clientName}</Typography>
                    <Typography>{v.notes}</Typography>
                  </Box>
                ))}
              </AccordionDetails>
            </Accordion>
          ))}
        </AccordionDetails>
      </Accordion>
    );
  };

  return (
    <Layout title={Utils.getTranslation(language, translator.pages.reports.title)}>
      <Box sx={{
        minHeight: 'calc(100vh - 64px)', width: '100%', padding: '32px 12px 12px', '@media (max-width:565px)': { padding: '8px 0 0' },
      }}>
        <Container>
          <Box
            sx={{
              padding: '20px 0',
            }}
          >
            <h1 style={{ color: 'white' }}>Rapport de visites</h1>
            {/* Tabs for weekly & monthly */}
            {/* Dropdown for each weeks / months with stats visible, all visites in dropdown */}
            <Box sx={{ borderBottom: 1, borderColor: 'divider', color: 'white' }}>
              <Tabs value={tabValue} onChange={handleChange} textColor='inherit'>
                <Tab label="Visites par semaine" />
              </Tabs>
            </Box>
            <CustomTabPanel value={tabValue} index={0}>
              {weeklySortedVisites && weeklySortedVisites.map((week:any, index:any) => displayWeek(week, index))}
            </CustomTabPanel>
          </Box>
        </Container>
      </Box>
    </Layout>
  );
};

export default ReportsPage;
