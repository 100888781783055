import {
  Box,
  Stack,
} from '@mui/material';

import { useEffect, useState } from 'react';

import ClientType from '../../../models/ClientType';
import { useAppSelector } from '../../../store/Hooks';
import VisiteCardAdd from './VisiteCardAdd';
import VisiteCard from './VisiteCard';

type Props = {
    client: ClientType,
};

const VisitesList: React.FC<Props> = ({ client }) => {
  const { visites } = useAppSelector((state) => state.user);
  const [currentVisites, setCurrentVisites] = useState([]) as any;

  useEffect(() => {
    if (client && visites) {
      const tempCurrentVisites = visites.filter((visite:any) => visite.clientId === client.id);
      setCurrentVisites(tempCurrentVisites.reverse());
    }
  }, [visites, client]);

  return (
    <>
      <VisiteCardAdd clientId={client.id} clientName={client.name || ''} />
      <Stack spacing={1}>
        {
          currentVisites.map((visite: any, index: number) => (
            <Box key={index}>
              <VisiteCard clientId={client.id} visite={visite} />
            </Box>
          ))
        }
      </Stack>
    </>
  );
};

export default VisitesList;
