import {
  collection, deleteDoc, doc, setDoc, Timestamp, updateDoc,
} from 'firebase/firestore';

import { db } from '../FirebaseConfig';

class VisiteService {
  static create = async (userId:string, clientId:string, clientName:string, vendeur:string, notes:string, date: Timestamp) => {
    const visiteDocRef = await doc(collection(db, 'Users', userId, 'Visites'));

    const visite = {
      id: visiteDocRef.id,
      clientId,
      clientName,
      vendeur,
      date,
      notes,
    };

    await setDoc(visiteDocRef, visite);
  };

  /**
   * Updates suivi document
   *
   * @param {string} userId
   * @param {any} suivi
   * @returns {timestamp} write time
   */
  static update = async (userId: string, visiteId: string, visite: any) => {
    await updateDoc(doc(db, 'Users', userId, 'Visites', visiteId), { ...visite });
  };

  /**
   * delete suivi document
   *
   * @param {string} userId
   * @param {Suivi} suivi
   * @returns {timestamp} write time
   */
  static delete = async (userId: string, visite: any) => deleteDoc(doc(db, 'Users', userId, 'Visites', visite.id));
}

export default VisiteService;
