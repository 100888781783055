import { Box, Container } from '@mui/material';
import { useAppSelector } from '../store/Hooks';
import User from '../models/User';
import SupportedLanguages from '../models/enums/SupportedLanguages';

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      'stripe-pricing-table': React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLElement>,
        HTMLElement
      >;
    }
  }
}

const PricingPage: React.FC = () => {
  const user = useAppSelector((state) => state.user.user) as User;
  const language = useAppSelector((state) => state.user.language) as SupportedLanguages;

  return (
    <Container>
      <Box
        sx={{
          padding: '120px 0',
        }}
      >
        {user && process.env.REACT_APP_LIVE_ENV !== 'dev' && <stripe-pricing-table
          pricing-table-id={(language === SupportedLanguages.FR) ? 'prctbl_1PDbvtAHBvH49FsgAMpkghrB' : 'prctbl_1PDqzOAHBvH49Fsg0Vdxcf1F'}
          publishable-key="pk_live_51McC7XAHBvH49Fsg78J87LNZ1SxwWQF3CBF8XaESjuoblXQ5hx1Avhj7urzfyqKVkYBVcjo5WfgdZIvlsm1JsFyy00fkzchDYO"
          customer-email={user.email}
          client-reference-id={user.id}
        ></stripe-pricing-table>}
        {user && process.env.REACT_APP_LIVE_ENV === 'dev' && <stripe-pricing-table
          pricing-table-id="prctbl_1QEh3ZAHBvH49FsgWmuiWf9R"
          publishable-key="pk_test_51McC7XAHBvH49FsgfWzpr03MRHb3YGW3PWWWGu2TvhUniJSZ9YnpDasRCnBx05RhkWxmmhovA82hHOB9taeTFrnO00cl38BZl7"
          customer-email={user.email}
          client-reference-id={user.id}
        ></stripe-pricing-table>}
      </Box>
    </Container>
  );
};

export default PricingPage;
