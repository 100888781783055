import {
  Autocomplete,
  Box, Popover, TextField,
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Form, Formik } from 'formik';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';

import * as yup from 'yup';
import dayjs from 'dayjs';
import { useState } from 'react';
import { Delete } from '@mui/icons-material';
import { Timestamp } from 'firebase/firestore';
import Utils from '../../../utils/Utils';
import translator from '../../../theme/translator.json';
import { useAppDispatch, useAppSelector } from '../../../store/Hooks';
import SupportedLanguages from '../../../models/enums/SupportedLanguages';
import AlertUtils from '../../../utils/AlertUtil';
import VisiteService from '../../../services/VisiteService';
import UserService from '../../../services/UserService';

  type Props = {
      clientId: string,
      visite?: any,
  };

const VisiteCard: React.FC<Props> = ({
  clientId, visite,
}) => {
  const user = useAppSelector((state) => state.user.user);
  const language = useAppSelector((state) => state.user.language) as SupportedLanguages;
  const dispatch = useAppDispatch();
  const [anchorEl, setAnchorEl] = useState(null) as any;

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const visiteInitialValues = () : {date:number, vendeur:string, notes:string} => ({
    date: visite.date.seconds * 1000,
    vendeur: visite.vendeur,
    notes: visite.notes,
  });

  const handleDelete = () => {
    setAnchorEl(null);
    VisiteService.delete(user.id, visite);
  };

  return (
    <Box sx={{
      width: '100%',
      pb: 3,
    }}>
      <Formik
        initialValues={visiteInitialValues()}
        enableReinitialize
        validationSchema={yup.object({
          date: yup.string().required(Utils.getTranslation(language, translator.formMessages.requiredField)),
          vendeur: yup.string().required(Utils.getTranslation(language, translator.formMessages.requiredField)),
          notes: yup.string(),
        })}
        onSubmit={async (values, { setSubmitting }) => {
          try {
            console.log(values);
            await VisiteService.update(user.id, visite.id, {
              ...visite,
              vendeur: values.vendeur,
              notes: values.notes,
              date: Timestamp.fromDate(new Date(values.date)),
            });
            if (values.vendeur) UserService.updateVendeurs(user.id, user.vendeurs, values.vendeur);
            setSubmitting(false);
            AlertUtils.createSuccessAlert(Utils.getTranslation(language, translator.successMessages.noteSaved), dispatch);
          } catch (e) {
            AlertUtils.createErrorAlert(Utils.getTranslation(language, translator.errorMessages.general.unknown), dispatch);
          }
        }}
      >
        {(formikProps) => (
          <Form>
            <Box sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'top',
              gap: '10px',
              '@media (max-width:600px)': {
                flexWrap: 'wrap',
              },
            }}>
              <Box sx={{ width: '200px' }}>
                <Box sx={{
                  '@media (max-width:600px)': {
                    display: 'flex',
                    alignItems: 'center',
                    gap: 2,
                    mb: 1,
                  },
                }}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <MobileDatePicker
                      value={dayjs.unix(formikProps.values.date / 1000)}
                      onChange={(value) => {
                        formikProps.setFieldValue('date', (value) ? value.hour(23).unix() * 1000 : dayjs().hour(23).unix() * 1000, false);
                        formikProps.handleSubmit();
                      }}
                      inputFormat="DD/MM/YYYY"
                      renderInput={(params: any) => <TextField
                        size="small"
                        error={formikProps.touched.notes && Boolean(formikProps.errors.notes)}
                        {...params}
                      />}
                    />
                  </LocalizationProvider>
                </Box>
              </Box>
              <Box>
                <Autocomplete
                  sx={{ minWidth: '200px' }}
                  id="vendeur"
                  size="small"
                  disableClearable
                  options={(user.vendeurs) ? user.vendeurs : []}
                  value={formikProps.values.vendeur}
                  onChange={(e, value) => {
                    formikProps.setFieldValue('vendeur', (value) || '');
                    formikProps.handleSubmit();
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                    />
                  )}
                />
              </Box>
              <Box sx={{ width: '100%' }}>
                <TextField
                  variant="outlined"
                  fullWidth
                  multiline
                  name="notes"
                  size="small"
                  value={formikProps.values.notes}
                  onChange={formikProps.handleChange}
                  onBlur={() => formikProps.handleSubmit()}
                  error={formikProps.touched.notes && Boolean(formikProps.errors.notes)}
                  helperText={formikProps.touched.notes && formikProps.errors.notes}
                />
              </Box>
              <Box onClick={(e) => handleClick(e)} sx={{
                width: 30,
                flexShrink: 0,
                borderRadius: 1,
                maxHeight: 40,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                color: 'secondary.main',
                cursor: 'pointer',
              }}><Delete /></Box>
              <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
              >
                <Box sx={{
                  p: 1, fontSize: '13px', fontWeight: '600', color: 'secondary.main', cursor: 'pointer',
                }} onClick={() => handleDelete()}>Supprimer</Box>
              </Popover>
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default VisiteCard;
