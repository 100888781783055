import {
  Autocomplete,
  Box, TextField,
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Form, Formik } from 'formik';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';

import * as yup from 'yup';
import dayjs from 'dayjs';
import { Timestamp } from 'firebase/firestore';
import Utils from '../../../utils/Utils';
import translator from '../../../theme/translator.json';
import { useAppDispatch, useAppSelector } from '../../../store/Hooks';
import SupportedLanguages from '../../../models/enums/SupportedLanguages';
import AlertUtils from '../../../utils/AlertUtil';
import VisiteService from '../../../services/VisiteService';
import UserService from '../../../services/UserService';

  type Props = {
      clientId: string,
      clientName: string
  };

const VisiteCardAdd: React.FC<Props> = ({
  clientId,
  clientName,
}) => {
  const user = useAppSelector((state) => state.user.user);
  const language = useAppSelector((state) => state.user.language) as SupportedLanguages;
  const dispatch = useAppDispatch();

  const visiteInitialValues = () : {date:number, vendeur:string, notes:string} => {
    const date = dayjs().hour(23).unix() * 1000;
    const vendeur = '';
    const notes = '';

    return {
      date,
      vendeur,
      notes,
    };
  };

  return (
    <Box sx={{
      width: '100%',
      pb: 6,
    }}>
      <Formik
        initialValues={visiteInitialValues()}
        enableReinitialize
        validationSchema={yup.object({
          date: yup.string().required(Utils.getTranslation(language, translator.formMessages.requiredField)),
          vendeur: yup.string().required(Utils.getTranslation(language, translator.formMessages.requiredField)),
          notes: yup.string(),
        })}
        onSubmit={async (values, { setSubmitting }) => {
          try {
            await VisiteService.create(user.id, clientId, clientName, values.vendeur, values.notes, Timestamp.fromDate(new Date(values.date)));
            if (values.vendeur) UserService.updateVendeurs(user.id, user.vendeurs, values.vendeur);
            setSubmitting(false);
            AlertUtils.createSuccessAlert(Utils.getTranslation(language, translator.successMessages.noteSaved), dispatch);
          } catch (e) {
            AlertUtils.createErrorAlert(Utils.getTranslation(language, translator.errorMessages.general.unknown), dispatch);
          }
        }}
      >
        {(formikProps) => (
          <Form>
            <Box sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'top',
              gap: '10px',
              '@media (max-width:600px)': {
                flexWrap: 'wrap',
              },
            }}>
              <Box sx={{ width: '200px' }}>
                <Box sx={{
                  '@media (max-width:600px)': {
                    display: 'flex',
                    alignItems: 'center',
                    gap: 2,
                    mb: 1,
                  },
                }}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <MobileDatePicker
                      label={Utils.getTranslation(language, translator.formLabels.visiteDate)}
                      value={dayjs.unix(formikProps.values.date / 1000)}
                      onChange={(value) => {
                        formikProps.setFieldValue('date', (value) ? value.hour(23).unix() * 1000 : dayjs().hour(23).unix() * 1000, false);
                      }}
                      inputFormat="DD/MM/YYYY"
                      renderInput={(params: any) => <TextField
                        size="small"
                        error={formikProps.touched.notes && Boolean(formikProps.errors.notes)}
                        {...params}
                      />}
                    />
                  </LocalizationProvider>
                </Box>
              </Box>
              <Box>
                <Autocomplete
                  sx={{ minWidth: '200px' }}
                  id="vendeur"
                  size="small"
                  options={(user.vendeurs) ? user.vendeurs : []}
                  freeSolo
                  value={formikProps.values.vendeur}
                  onInputChange={(e, value) => {
                    formikProps.setFieldValue('vendeur', (value) || '');
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Vendeur"
                    />
                  )}
                />
              </Box>
              <Box sx={{ width: '100%' }}>
                <TextField
                  label={Utils.getTranslation(language, translator.formLabels.notesVisite)}
                  variant="outlined"
                  fullWidth
                  multiline
                  name="notes"
                  size="small"
                  value={formikProps.values.notes}
                  onChange={formikProps.handleChange}
                  error={formikProps.touched.notes && Boolean(formikProps.errors.notes)}
                  helperText={formikProps.touched.notes && formikProps.errors.notes}
                />
              </Box>
              <Box onClick={() => {
                formikProps.handleSubmit();
              }} sx={{
                width: 30,
                flexShrink: 0,
                borderRadius: 1,
                maxHeight: 40,
                backgroundColor: 'secondary.main',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                color: 'white',
                cursor: 'pointer',
              }}>+</Box>
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default VisiteCardAdd;
