import {
  collection, deleteDoc, doc, setDoc, Timestamp, updateDoc,
} from 'firebase/firestore';

import { db } from '../FirebaseConfig';
import VisibilityState from '../models/enums/VisibilityState';
import Suivi from '../models/Suivi';

class SuiviService {
  static create = async (userId:string, clientId:string, notes:string, date: Timestamp, vendeur?:string) => {
    const suiviDocRef = await doc(collection(db, 'Users', userId, 'Suivis'));

    const suivi = {
      id: suiviDocRef.id,
      clientId,
      createdDate: new Date(),
      date,
      notes,
      vendeur,
      visibility: VisibilityState.VISIBLE,
    };

    await setDoc(suiviDocRef, suivi);
  };

  /**
   * Updates suivi document
   *
   * @param {string} userId
   * @param {any} suivi
   * @returns {timestamp} write time
   */
  static update = async (userId: string, suiviId: string, suivi: any) => {
    await updateDoc(doc(db, 'Users', userId, 'Suivis', suiviId), { ...suivi });
  };

  /**
   * delete suivi document
   *
   * @param {string} userId
   * @param {Suivi} suivi
   * @returns {timestamp} write time
   */
  static delete = async (userId: string, suivi: Suivi) => deleteDoc(doc(db, 'Users', userId, 'Suivis', suivi.id));
}

export default SuiviService;
