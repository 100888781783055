import {
  User as FirebaseUser, updateEmail, updatePassword, signInWithEmailAndPassword, signOut, createUserWithEmailAndPassword, reauthenticateWithCredential,
} from 'firebase/auth';
import {
  doc, setDoc, deleteDoc, updateDoc, getDoc,
} from 'firebase/firestore';

import { httpsCallable } from 'firebase/functions';
import { auth, db, functions } from '../FirebaseConfig';
import PricingPlan from '../models/enums/PricingPlans';
import Role from '../models/enums/Roles';
import VisibilityState from '../models/enums/VisibilityState';

class UserService {
  /**
   * Sign in a user by email & password
   *
   * @param {string} email
   * @param {string} password
   * @returns {void}
   */
  static login = (email: string, password: string) => signInWithEmailAndPassword(auth, email, password);

  /**
   * Sign out a user
   *
   * @returns {void}
   */
  static logout = () => signOut(auth);

  /**
   * Create a firebase user
   *
   * @param {string} email
   * @param {string} password
   * @returns {void}
   */
  static createAccount = (email: string, password: string) => createUserWithEmailAndPassword(auth, email, password);

  // TODO translate user creation
  /**
   * Create a user document
   *
   * @param {string} id
   * @param {string} name
   * @param {string} email
   * @param {string} language
   * @returns {timestamp} write time
   */
  static create = async (id:string, name: string, email: string, phone: string, language?: string, reference?: string) => setDoc(doc(db, 'Users', id), {
    id,
    name,
    email,
    phone,
    role: Role.USER,
    createdDate: new Date(),
    plan: PricingPlan.FREEMIUM,
    documentsCounter: {
      clientsCounter: 0,
      suivisCounter: 0,
    },
    visibility: VisibilityState.VISIBLE,
    language,
    personalizedLinks: [
      {
        title: 'Calendrier',
        url: '',
      },
      {
        title: 'Boîte courriel',
        url: '',
      },
      {
        title: 'Zoom',
        url: '',
      },
      {
        title: 'Teams',
        url: '',
      },
    ],
    clientTags: ['A', 'B', 'C'],
    ref: reference,
  });

  static updateTags = async (id: string, currentTags: string[], newTags : string[]) => {
    if (!newTags) return;

    const tagsToAdd = [] as string[];
    // eslint-disable-next-line no-restricted-syntax
    for (const newTag of newTags) {
      let isNewTag = true;
      // eslint-disable-next-line no-restricted-syntax
      for (const currentTag of currentTags) {
        if (newTag === currentTag) {
          isNewTag = false;
          break;
        }
      }
      if (isNewTag) {
        tagsToAdd.push(newTag);
      }
    }

    tagsToAdd.concat(currentTags);
    updateDoc(doc(db, 'Users', id), {
      clientTags: tagsToAdd.concat(currentTags),
    });
  };

  static updateVendeurs = async (id: string, currentVendeurs: any, newVendeur : string) => {
    if (!newVendeur) return;
    if (currentVendeurs && currentVendeurs.includes(newVendeur)) return;

    let currentVendeursTemp = [] as any;

    if (currentVendeurs) {
      currentVendeursTemp = [...currentVendeurs];
    }

    if (!Array.isArray(currentVendeursTemp)) currentVendeursTemp = [];

    console.log(currentVendeursTemp);
    currentVendeursTemp.push(newVendeur);

    updateDoc(doc(db, 'Users', id), {
      vendeurs: currentVendeursTemp,
    });
  };

  /**
   * Gets a user document by id
   *
   * @param {string} id
   * @returns {User} user
   */
  static get = async (id: string) => (await getDoc(doc(db, 'Users', id))).data();

  /**
   * Delete user firebase user and Stripe subscriptions
   *
   * @param {firebaseUser} firebaseUser
   * @returns {timestamp} write time
   */
  static deleteAccount = async (firebaseUser: FirebaseUser, userId: string, subId: string) => {
    firebaseUser.delete();
    const cancelUserStripeSubscription = httpsCallable(functions, 'stripe-cancelUserStripeSubscription');
    const response = await cancelUserStripeSubscription({
      userId, subId,
    }) as any;
    console.log(response);
  };

  /**
   * Delete user document
   *
   * @param {string} id
   * @returns {timestamp} write time
   */
  static delete = (id: string) => deleteDoc(doc(db, 'Users', id));

  /**
   * Updates user password
   *
   * @param {FirebaseUser} firebaseUser
   * @param {string} newPassword
   * @returns {timestamp} write time
   */
  static updatePassword = (currentUser: FirebaseUser, newPassword: string) => updatePassword(currentUser, newPassword);

  /**
   * Updates user email
   *
   * @param {FirebaseUser} firebaseUser
   * @param {string} email
   * @returns {timestamp} write time
   */
  static updateEmail = (currentUser: FirebaseUser, newEmail: string) => updateEmail(currentUser, newEmail);

  /**
   * Updates user document
   *
   * @returns {timestamp} write time
   */
  static update = (id: string, user: any) => updateDoc(doc(db, 'Users', id), user);

  /**
   * Reauthenticate user
   *
   * @param {FirebaseUser} firebaseUser
   * @param {string} password
   * @returns {timestamp} write time
   */
  static reauthenticate = (currentUser: FirebaseUser, password: any) => reauthenticateWithCredential(currentUser, password);
}

export default UserService;
