import '../../theme/css/global.css';
import {
  Box, Container, Dialog, Divider, IconButton, Slide, Tooltip, Typography, useMediaQuery, useTheme,
} from '@mui/material';

import {
  forwardRef, useEffect, useState,
} from 'react';

import { TransitionProps } from '@mui/material/transitions';
import { Delete } from '@mui/icons-material';
import { useAppDispatch, useAppSelector } from '../../store/Hooks';
import ClientType from '../../models/ClientType';
import Profile from './components/Profile';
import Header from './components/Header';
import themeConfig from '../../theme/ThemeConfig';
import Suivi from '../../models/Suivi';
import SuivisList from './components/SuivisList';
import Spinner from '../Spinner';
import AlertUtils from '../../utils/AlertUtil';
import ClientActions from './components/ClientActions';
import DraftSuiviAdd from './components/DraftSuiviAdd';
import DeleteClientConfirmation from '../../common/DeleteClientConfirmation';
import { setClientPopupOpen } from '../../store/slices/ClientSlice';
import Utils from '../../utils/Utils';
import translator from '../../theme/translator.json';
import SupportedLanguages from '../../models/enums/SupportedLanguages';
import FilesSave from './components/FilesSave';
import VisitesList from './components/VisitesList';
import PricingPlan from '../../models/enums/PricingPlans';

const Transition = forwardRef((
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) => <Slide direction="up" ref={ref} {...props} />);

const ClientsSummary: React.FC = () => {
  const dispatch = useAppDispatch();
  const { suivis, clients, user } = useAppSelector((state) => state.user);
  const { clientPopupOpen, orderedClientsList, clientOpenId } = useAppSelector((state) => state.client);
  const language = useAppSelector((state) => state.user.language) as SupportedLanguages;

  const [loading, setLoading] = useState(false);

  const [deleteClientDialogOpen, setDeleteClientDialogOpen] = useState(false);
  const [currentClient, setCurrentClient] = useState(undefined) as any | ClientType;
  const [currentSuivis, setCurrentSuivis] = useState([]) as any | Suivi[];
  const [currentClientIndex, setCurrentClientIndex] = useState(undefined) as any;
  const [isOpenTimeoutDone, setIsOpenTimeoutDone] = useState(false);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    try {
      setLoading(true);
      if (clientOpenId && clients && clientPopupOpen) {
        const clientEntryPoint = orderedClientsList.find((c:ClientType, index: number) => {
          if (c.id === clientOpenId) {
            setCurrentClientIndex(index);
            return c;
          }
          return undefined;
        });
        setCurrentClient(clientEntryPoint);
        if (clientEntryPoint) {
          const tempCurrentSuivis = suivis.filter((suivi: Suivi) => suivi.clientId === clientEntryPoint.id);
          setCurrentSuivis(tempCurrentSuivis);
        } else {
          handleCloseDialog();
        }
      }

      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.error(e);
      AlertUtils.createErrorAlert(Utils.getTranslation(language, translator.errorMessages.general.unknown), dispatch);
    }
  }, [clientPopupOpen]);

  useEffect(() => {
    try {
      if (currentClient && clientPopupOpen && suivis) {
        const tempCurrentSuivis: Suivi[] = suivis.filter((suivi: Suivi) => suivi.clientId === currentClient.id);
        setCurrentSuivis(tempCurrentSuivis);
      }
    } catch (e) {
      setLoading(false);
      console.error(e);
      AlertUtils.createErrorAlert(Utils.getTranslation(language, translator.errorMessages.general.unknown), dispatch);
    }
  }, [suivis, currentClient]);

  useEffect(() => {
    if (isOpenTimeoutDone) return;
    triggerOpenTimeout();
  }, [isOpenTimeoutDone]);

  const triggerOpenTimeout = () => {
    setTimeout(() => setIsOpenTimeoutDone(true), 1000);
  };

  const onClose = () => {
    dispatch(setClientPopupOpen(false));
  };

  const handleCloseDialog = () => {
    setCurrentClientIndex(undefined);
    setCurrentClient(undefined);
    setCurrentSuivis([]);
    setIsOpenTimeoutDone(false);
    onClose();
  };

  const handleCloseDialogBackdrop = (event:any, reason:any) => {
    if (reason && reason === 'backdropClick' && !isOpenTimeoutDone) { return; }
    setCurrentClientIndex(undefined);
    setCurrentClient(undefined);
    setCurrentSuivis([]);
    setIsOpenTimeoutDone(false);
    onClose();
  };

  const handleNextClient = () => {
    if (currentClientIndex + 1 === orderedClientsList.length) {
      setCurrentClient(orderedClientsList[0]);
      setCurrentClientIndex(0);
    } else {
      setCurrentClient(orderedClientsList[currentClientIndex + 1]);
      setCurrentClientIndex(currentClientIndex + 1);
    }
  };

  const handlePreviousClient = () => {
    if (currentClientIndex - 1 === -1) {
      setCurrentClient(orderedClientsList[orderedClientsList.length - 1]);
      setCurrentClientIndex(orderedClientsList.length - 1);
    } else {
      setCurrentClient(orderedClientsList[currentClientIndex - 1]);
      setCurrentClientIndex(currentClientIndex - 1);
    }
  };

  return (
    <>
      <Dialog
        maxWidth='xl'
        fullWidth
        fullScreen={fullScreen}
        open={clientPopupOpen}
        onClose={handleCloseDialogBackdrop}
        disableEnforceFocus
        TransitionComponent={Transition}
        sx={{ '@media (min-width:900px)': { pt: '35px' } }}
      >
        {currentClient && clientPopupOpen
          && <>
            <Header
              orderedClientsList={orderedClientsList}
              onPreviousClient={handlePreviousClient}
              clientIndex={currentClientIndex}
              onNextClient={handleNextClient}
              client={currentClient}
              onClose={handleCloseDialog}/>
            <Container sx={{
              maxWidth: 'xl',
            }}>
              <Box sx={{
                display: 'flex', flexWrap: 'wrap', alignItems: 'flex-start', justifyContent: 'space-between', gap: '40px', m: themeConfig.spacing(4, 0, 4, 0),
              }}>
                <Box sx={{
                  width: '100%',
                  maxWidth: '700px',
                }}>
                  <Profile handleCloseSummary={handleCloseDialog} client={currentClient} />
                </Box>
                <Box sx={{
                  width: '100%',
                  maxWidth: '400px',
                }}>
                  <Typography variant="h6">
                    <strong>{Utils.getTranslation(language, translator.components.ficheClient.dateProchainSuivi)}</strong>
                  </Typography>
                  <Divider
                    sx={{
                      m: themeConfig.spacing(1, 0, 2, 0),
                    }} />
                  <DraftSuiviAdd clientId={currentClient.id} nextSuivi={currentClient.nextSuivi}/>
                  <Box sx={{
                    m: themeConfig.spacing(6, 0, 0, 0),
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                  }}>
                    <Typography variant="h6"><strong>{Utils.getTranslation(language, translator.components.ficheClient.fichiers)}</strong></Typography>
                  </Box>
                  <Divider
                    sx={{
                      m: themeConfig.spacing(1, 0, 2, 0),
                    }} />
                  <FilesSave client={currentClient} />
                </Box>
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: 4 }}>
                <Typography variant="h6"><strong>{Utils.getTranslation(language, translator.components.ficheClient.modifyStatus)}</strong></Typography>
                <Tooltip title={Utils.getTranslation(language, translator.components.ficheClient.deleteClient)}>
                  <IconButton size="small" onClick={() => setDeleteClientDialogOpen(true) }>
                    <Delete fontSize='small' />
                  </IconButton>
                </Tooltip>
              </Box>
              <Divider
                sx={{
                  m: themeConfig.spacing(2, 0, 3, 0),
                }} />
              <ClientActions client={currentClient} />
              {
                user?.plan === PricingPlan.PREMIUM && user.showVisites
                && <><Box sx={{
                  m: themeConfig.spacing(6, 0, 0, 0),
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                }}>
                  <Box sx={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    gap: '20px',
                    mr: 2,
                  }}>
                    <Typography variant="h6"><strong>{Utils.getTranslation(language, translator.components.ficheClient.visites.title)}</strong></Typography>
                  </Box>
                </Box>
                <Divider
                  sx={{
                    m: themeConfig.spacing(2, 0, 3, 0),
                  }} />
                <VisitesList client={currentClient} />
                </>}
              <Box sx={{
                m: themeConfig.spacing(6, 0, 0, 0),
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
              }}>
                <Box sx={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  gap: '20px',
                  mr: 2,
                }}>
                  <Typography variant="h6"><strong>{Utils.getTranslation(language, translator.components.ficheClient.notes)}</strong></Typography>
                </Box>
              </Box>
              <Divider
                sx={{
                  m: themeConfig.spacing(2, 0, 3, 0),
                }} />
              <Box>
                <SuivisList client={currentClient} suivis={currentSuivis} suiviFocusId={currentClient.suiviFocusId} />
              </Box>
            </Container>
          </>
        }
      </Dialog>
      <Spinner show={loading}/>
      {currentClient && <DeleteClientConfirmation
        open={deleteClientDialogOpen}
        handleClose={() => setDeleteClientDialogOpen(false)}
        handleCloseSummary={onClose}
        clients={[currentClient]}
      />}
    </>
  );
};

export default ClientsSummary;
